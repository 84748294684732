import { Controller } from "stimulus"
import tippy from 'tippy.js';
import Timer from 'easytimer.js';
// window.easytimer = easytimer;

export default class extends Controller {
  static targets = ["countdown"]

  connect() {
    // console.log("timer connected")
    const countdown = this.countdownTarget
    var date = new Date(countdown.dataset.date)
    var diff = ((date.getTime() - Date.now()) / 1000)

    if (Math.sign(diff) == -1) {
      countdown.innerHTML = "00:00"
      countdown.classList.add("animate-pulse")
    }
    else {
      var timer = new Timer();

      timer.start({countdown: true, startValues: {seconds: diff}})

      timer.addEventListener('secondsUpdated', function (e) {
        countdown.innerHTML = timer.getTimeValues().toString(['minutes', 'seconds'])
      })

      timer.addEventListener('targetAchieved', function (e) {
          countdown.innerHTML = "00:00"
          countdown.classList.add("animate-pulse")
      });
    }
  }
}