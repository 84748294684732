window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("The filetype is not supported. Please upload either a jpg png, gif file.")
  }

  const maxFileSize = 1024 * 1024 * 3 // 1MB 
  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert("Attachments must be under 3 MB.")
  }
})