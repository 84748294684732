import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["close"]

  connect() {
    const closeElement = this.closeTarget
    setTimeout(() => { this.dismiss() }, 7000);
  }

  dismiss() {
  	//allow for transition animation and then remove the element
  	//make sure the timeout matched the tansition timing in css +10ms for cover
    this.element.classList.add('opacity-0');
    setTimeout(() => { this.element.style.display = 'none'; }, 500);
    setTimeout(() => { this.element.remove; }, 500);
  }
}