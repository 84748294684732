import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"

    // find all existing 'border-pulse-nested' and remove them
    var elements = document.getElementsByClassName('border-pulse-nested')
    while (elements[0]) {
      elements[0].classList.remove('border-pulse-nested')
    }
  }

  add_association(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_NESTED_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)

    // var first = document.querySelector('.border-pulse-nested');

    // grab all instances of 'border-pulse-nested' and remove them in case user spams the add button
    var elements = document.getElementsByClassName('border-pulse-nested')
    setTimeout(function() {
      while (elements[0]) {
        elements[0].classList.remove('border-pulse-nested')
      }
    }, 325);
  }

  remove_association(event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.wrapperClass)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }
}