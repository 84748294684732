import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu']

  connect() {
    // this.toggleClass = this.data.get('class') || 'hidden'
  }

  toggle() {
    // this.menuTarget.classList.toggle(this.toggleClass)

    if (!this.menuTarget.classList.contains('show')) {
      this.menuTarget.style.display = 'block';
      setTimeout(() => {
        this.menuTarget.classList.add('show');
      }, 10);
    }
    else {
      this.menuTarget.classList.remove('show');
      setTimeout(() => {
        this.menuTarget.style.display = 'none';
      }, 110);
    }
  }

  hide(event) {
    if (
      this.element.contains(event.target) === false &&
      this.menuTarget.classList.contains('show')
    ) {
      this.menuTarget.classList.remove('show');
      setTimeout(() => {
        this.menuTarget.style.display = 'none';
      }, 110);
    }
  }
}