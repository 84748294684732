import { Controller } from "stimulus"
import clipboard from 'clipboard'

export default class extends Controller {
  connect() {
  	window.clipboard = clipboard
	var clipboardinst = new clipboard('.link-copy')

	const tooltip = document.querySelector("#roomCode")

	tippy(tooltip, {
	    animation: 'scale',
	    content: 'Copy Link',
	    hideOnClick: false,
	});

	clipboardinst.on('success', function(e) {
		tooltip._tippy.setContent('Copied!');
	    // document.getElementById("tooltip-inner").textContent = "Copied!"
	    setTimeout(() => { tooltip._tippy.setContent('Copy Link'); }, 2000)
	    e.clearSelection()
	})
  }
}