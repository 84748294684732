import Rails from "@rails/ujs";
import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this)
    })


    // let el = document.getElementsByClassName('question');

    // for (var i = 0; i < el.length; i++) {
    //   Sortable.create(el[i], {
    //   group: 'shared',
    //   animation: 150,
    //   onEnd: this.end.bind(this)
    // })
    // }
  }

  end(event) {
    console.log(event)
    let id = event.item.dataset.id
    let round_id = event.to.parentElement.dataset.id
    console.log(round_id)
    let data = new FormData()
    data.append("round_id", round_id)
    data.append("position", event.newIndex + 1)

    console.log(data)

    Rails.ajax({
      url: this.data.get("url").replace(":id", id),
      type: 'PATCH',
      data: data
    })
  }
}