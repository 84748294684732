import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "correct", "half", "score" ]

	updateScore(event) {
	    // get current value in total text box, using parseFloat since it is a string
	    var val = this.scoreTarget.value;
	    val = parseFloat(val)

	    // get the total points for the question
	    var points = parseFloat(event.target.dataset.points)
	    
	    // if checkbox is checked, add its value to val, otherwise set score to 0
	    // correct
	    if (event.target.dataset.target == "checkbox.correct") {
	    	if (this.correctTarget.checked == true) {
		        val = points
		        this.halfTarget.checked = false
		        console.log("checked")
	    	}

	    	else {
		        val = 0
		        console.log("checked")
	    	}
	    }

		// half
	    if (event.target.dataset.target == "checkbox.half") {
	    	if (this.halfTarget.checked == true) {
		        val = points/2
		        this.correctTarget.checked = false
		        console.log("checked")
	    	}

	    	else {
		        val = 0
		        console.log("checked")
	    	}
	    }

	    // ,anual score
	    if (event.target.dataset.target == "checkbox.score") {
	    	console.log(this.scoreTarget.value)
	    	console.log(event.target.dataset.points)

	    	if (this.scoreTarget.value == event.target.dataset.points) {
	    		this.correctTarget.checked = true
	    		this.halfTarget.checked = false
	    	}
	    	else if (this.scoreTarget.value == (event.target.dataset.points/2)) {
	    		this.halfTarget.checked = true
	    		this.correctTarget.checked = false
	    	}
	    	else {
	    		this.correctTarget.checked = false
	    		this.halfTarget.checked = false
	    	}
	    }

	    // update the value 
	    this.scoreTarget.value = val
	}
}