import { Controller } from 'stimulus'
import CableReady from "cable_ready"

export default class extends Controller {
  connect () {
    this.application.consumer.subscriptions.create('NotificationChannel', {
      received (data) {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    })
  }
}