import { Controller } from "stimulus"
import Shepherd from 'shepherd.js';
// import 'shepherd.js/dist/css/shepherd.css';
window.Shepherd = Shepherd;

export default class extends Controller {
  connect() {
  	document.getElementsByTagName("html")[0].setAttribute("style", "scroll-behavior: smooth;");

	const tour = new Shepherd.Tour({
	  defaultStepOptions: {
	    classes: 'shadow-md',
	    scrollTo: { behavior: 'smooth', block: 'center' }
	  }
	});

	window.tour = tour;

	// tour.addStep({
	//   id: 'get_started',
	//   title: 'Getting started',
	//   text: "Welcome! Let's get started creating your first quiz.",
	//   buttons: [
	//   	{
	//     	text: 'Seen this before? Skip the tips',
	//     	secondary: true,
	//     	action: tour.complete
	//     },
	//     {
	//       text: 'Get started',
	//       action: tour.next
	//     },
	//   ],
	//   scrollTo: false,
	//   classes: 'p-10 shepherd-welcome',
	// });

	tour.addStep({
	  id: 'quiz_name',
	  title: 'Name your quiz',
	  text: "Every quiz needs a name. This is the title that will be shown to your players.",
	  attachTo: {
	    element: '#quiz_name',
	    on: 'bottom'
	  },
	  popperOptions: {
	  	modifiers: [{ name: 'offset', options: { offset: [20, 0] } }]
	  },
	  buttons: [
	    {
	      text: 'Next',
	      action: tour.next
	    },
	  ],
	  scrollTo: false
	});

	tour.addStep({
	  id: 'round_number',
	  title: 'Order your rounds',
	  text: 'A quiz is made up of multiple rounds. The round number is the order your round will be played in, and must be unique.',
	  attachTo: {
	    element: '#quiz_rounds_attributes_0_number',
	    on: 'top'
	  },
	  buttons: [
	    {
	      text: 'Next',
	      action: tour.next
	    }
	  ],
	  scrollTo: false
	});

	tour.addStep({
	  id: 'round_desc',
	  title: 'Describe your round',
	  text: "Have a preamble? Add an optional round description.",
	  attachTo: {
	    element: '#quiz_rounds_attributes_0_description',
	    on: 'top'
	  },
	  buttons: [
	    {
	      text: 'Next',
	      action: tour.next
	    }
	  ]
	});

	tour.addStep({
	  id: 'question_text',
	  title: 'Write your question',
	  text: "Enter the text of your question. Be as creative as you'd like.",
	  attachTo: {
	    element: '#quiz_rounds_attributes_0_questions_attributes_0_text',
	    on: 'top'
	  },
	  buttons: [
	    {
	      text: 'Next',
	      action: tour.next
	    }
	  ]
	});

	tour.addStep({
	  id: 'add_image',
	  title: 'Add an image',
	  text: 'You can add images and gifs to your question. Spice up your quiz!',
	  attachTo: {
	    element: '#trix-toolbar-2 > div.trix-button-row > span.trix-button-group.trix-button-group--file-tools > button',
	    on: 'bottom-start'
	  },
	  popperOptions: {
	  	modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
	  },
	  buttons: [
	    {
	      text: 'Next',
	      action: tour.next
	    }
	  ]
	});

	tour.addStep({
	  id: 'add_link',
	  title: 'Add a link',
	  text: "Link to anything including Youtube, Spotify, Google Drive & Dropbox.",
	  attachTo: {
	    element: '#trix-toolbar-2 > div.trix-button-row > span.trix-button-group.trix-button-group--text-tools > button.trix-button.trix-button--icon.trix-button--icon-link',
	    on: 'bottom-start'
	  },
	  popperOptions: {
	  	modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
	  },
	  buttons: [
	    {
	      text: 'Next',
	      action: tour.next
	    }
	  ]
	});

	tour.addStep({
	  id: 'answer',
	  title: 'Answer the question',
	  text: "Provide an optional answer for easy reference when marking.",
	  attachTo: {
	    element: '#quiz_rounds_attributes_0_questions_attributes_0_answer',
	    on: 'right-start'
	  },
	  popperOptions: {
	  	modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
	  },
	  buttons: [
	    {
	      text: 'Next',
	      action: tour.next
	    }
	  ]
	});

	tour.addStep({
	  id: 'add_points',
	  title: 'Assign points',
	  text: "Wrote a hard question? Assign points as you see fit. You can award up to this many points when marking.",
	  attachTo: {
	    element: '#quiz_rounds_attributes_0_questions_attributes_0_points',
	    on: 'left-start'
	  },
	  popperOptions: {
	  	modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
	  },
	  buttons: [
	    {
	      text: 'Next',
	      action: tour.next
	    }
	  ]
	});

	tour.addStep({
	  id: 'new_question',
	  title: 'Create a new question',
	  text: "Add as many questions as you'd like to your rounds.",
	  attachTo: {
	    element: '.add_question',
	    on: 'right-start'
	  },
	  popperOptions: {
	  	modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
	  },
	  buttons: [
	    {
	      text: 'Next',
	      action: tour.next
	    }
	  ]
	});

	tour.addStep({
	  id: 'new_round',
	  title: 'Create a second round',
	  text: "Add as many rounds as you'd like to your quiz.",
	  attachTo: {
	    element: '.add-round',
	    on: 'right-start'
	  },
	  popperOptions: {
	  	modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
	  },
	  buttons: [
	    {
	      text: 'Get started',
	      action: scrollToTop
	    }
	  ]
	});

	function scrollToTop(){
		tour.complete();
		window.scrollTo(0, 0);
		document.getElementById("quiz_name").focus();
	}

	// tour.start();
  }
}